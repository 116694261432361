var form = $('#form');
var btn = $('#form-submit');

function sendMail(token) {
	var assuntoFake = $('#form-fake-assunto').val();
	var assuntoTrue = $('#form-true-assunto').val();
	var assunto = assuntoTrue.replace('SUBJECT',assuntoFake);

	if (btn.attr('data-disabled') == 'false') {

		$('#form-true-assunto').val( assunto );

		$.ajax({
	    url: "https://formspree.io/jaae.comunicacao@gmail.com",
	    method: "POST",
	    data: form.serialize(),
	    dataType: "json",
	    beforeSend: function() {
				form.find('.sucesso').hide();
				form.find('.falha').hide();
				form.append('<span class="alerta loading">Enviando</span>');
			},
			success: function(data) {
				$('#form-true-assunto').val( assuntoTrue.replace(assuntoFake,'SUBJECT') );
				form.find('.loading').hide();
				form.find('.falha').hide();
				form.append('<span class="alerta sucesso">Sucesso</span>');
				setTimeout(removeOpacitySucesso, 1000);
				setTimeout(removeHeightSucesso, 2000);
				setTimeout(removeAttrInput, 1000);
				setTimeout(removeValInput, 1000);
			},
			error: function(err) {
				form.find('.loading').hide();
				form.find('.sucesso').hide();
				form.append('<span class="alerta falha">Falhou</span>');
			}
	  });
	}
}

function removeAttrInput(){
	$('.input').removeAttr('data-validate');
}

function removeValInput(){
	form.find('.input').val("");
}

function removeOpacitySucesso() {
	$('.sucesso').css({
			opacity : "0"
    });
}

function removeHeightSucesso() {
	$('.sucesso').css({
      height: "0",
			marginTop: "0"
    });
}
function validate(){
		var name = /^[a-zA-Z\u00C0-\u017F ]{2,40}$/;
		var assunto = /^[a-zA-Z\u00C0-\u017F ,.[\];0-9-!?:()"']+$/;
		var email =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var mensagem = /^.{7,}$/;

		var teste = $(this).val().replace(/\ /g,"") == "";

		if($(this).val() == "" || teste) {
				$(this).attr('data-validate','false');
	  }else if(
			$(this).attr('name') == 'Nome' && name.test($(this).val()) || $(this).attr('name') == 'Assunto' && assunto.test($(this).val()) || $(this).attr('name') == 'E-mail' && email.test($(this).val()) || $(this).attr('name') == 'Mensagem' && mensagem.test($(this).val())
		){
			$(this).attr('data-validate','true');

		}else{
			$(this).attr('data-validate','false');
		}
		enableBtn();
}

$('.input').on('keyup focusout', validate);
form.on('submit', function(e) {
	e.preventDefault();
});
btn.on('click touchstart', function(e){
	e.preventDefault();
	if ($(this).attr('data-disabled') == 'true') {
		var inputNotValid = form.find('.input:not(#form-submit):not([data-validate="true"])')
		inputNotValid.attr('data-validate','false');
		inputNotValid[0].focus();
	}else{
		grecaptcha.execute();
	}
});

function enableBtn() {
	var inputs = document.querySelectorAll('.input');
	var btn = document.querySelector('#form-submit');
	var btnDisabled = btn.getAttribute('data-disabled') == 'true';
	for (var i = 0; i < inputs.length; i++) {
		var validate = inputs[i].getAttribute('data-validate') == 'true';

		if(!validate) {
			btn.setAttribute('data-disabled','true');
			break;
		}else if(btnDisabled){
			btn.setAttribute('data-disabled','false');
		}
	}
}
